import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../components/Dropdown';
import { LANGUAGE_OPTIONS } from '../context/i18n';

const LanguageSelector = ({ className }: { className?: string }) => {
  const { i18n } = useTranslation();

  const handleSelection = (e: ChangeEvent<HTMLSelectElement>) => {
    i18n.changeLanguage(e.target.value);
  };

  return (
    <Dropdown
      className={className}
      value={i18n.language}
      options={LANGUAGE_OPTIONS}
      onChange={handleSelection}
    />
  );
};

export default LanguageSelector;
