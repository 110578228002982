import { Slider } from 'antd';
import { SliderValue } from 'antd/lib/slider';
import { FieldProps } from 'formik';
import { identity } from 'lodash';
import React from 'react';
import styled from 'styled-components/macro';
import { ScFormItem as FormItem, ScInputLabel } from './utils';

type Props = {
  className?: string;
  label: string;
  formatValueLabel?: (value: number) => string;
} & FieldProps<any>;

const FormSlider = ({
  form: { handleBlur },
  field,
  label,
  formatValueLabel = identity,
  className,
  ...props
}: Props) => (
  <>
    <ScHeader>
      <ScStyledInputLabel>{label}</ScStyledInputLabel>
      <ScValue>
        {Array.isArray(field.value)
          ? field.value.map(formatValueLabel).join(' - ')
          : formatValueLabel(field.value)}
      </ScValue>
    </ScHeader>
    <ScFormItem className={className} {...field}>
      <Slider
        {...props}
        tipFormatter={null}
        value={field.value}
        onChange={(updatedValue: SliderValue) => {
          field.onChange(field.name)(updatedValue);
        }}
        onAfterChange={handleBlur}
      />
    </ScFormItem>
  </>
);

export default FormSlider;

//
// Utils
//

const ScFormItem = styled(FormItem)`
  .ant-slider {
    .ant-slider-handle {
      margin-left: -12px !important;
      margin-top: -6px !important;
      width: 16px !important;
      height: 16px !important;
      border: solid 2px ${props => props.theme.colors.primary} !important;
      transition-property: transform, margin, width, height;
      transition-duration: 0.1s;
      transition-timing-function: ease;

      &:hover,
      &:active {
        width: 24px !important;
        height: 24px !important;
        border: solid 2px ${props => props.theme.colors.primary} !important;
        box-shadow: none !important;
        margin-left: -16px !important;
        margin-top: -10px !important;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .ant-slider-track {
      background-color: ${props => props.theme.colors.primary} !important;
    }

    &.ant-slider-disabled {
      .ant-slider-handle {
        border-color: rgba(0, 0, 0, 0.25) !important;

        &:hover,
        &:active {
          width: 16px !important;
          height: 16px !important;
          margin-left: -12px !important;
          margin-top: -6px !important;
          border-color: rgba(0, 0, 0, 0.25) !important;
        }
      }

      .ant-slider-track {
        background-color: rgba(0, 0, 0, 0.25) !important;
      }
    }
  }
`;
const ScHeader = styled.div`
  display: flex;
  width: 100%;
`;
const ScStyledInputLabel = styled(ScInputLabel)`
  flex-grow: 1;
`;
const ScValue = styled(ScInputLabel)`
  flex: none;
`;
