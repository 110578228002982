import { Item } from '@hypercharge/hyperleasing-commons/lib/types/cms';
import { PaginatedList } from '@hypercharge/lambda-utils/lib/types';
import { message } from 'antd';
import { isEmpty, map, partition } from 'lodash';
import React, { PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FetchValue, hyperfetch } from '../../utils/httpClient';
import { Status } from '../../utils/types';
import { useSplash } from '../splash/SplashProvider';

export type LeasingParameter = Item & {
  // Translations
  titleEn?: string;
  titleNl?: string;
  titleFr?: string;
  // Parameters
  civilLiability: number;
  specialInsurance: number;
  allRisksPercentage: number;
  allRisksTaxPercentage: number;
  yearlyRoadTax: number;
  oneTimeRoadTax: number;
  countries: Item[];
};

const SPLASH_KEY = 'leasing-parameters-provider';

type ContextValue = { leasingParameters?: LeasingParameter[]; status: Status };

const LeasingParametersContext = React.createContext<ContextValue | undefined>(undefined);

const LeasingParametersProvider = (props: PropsWithChildren<any>) => {
  const { addLoader, removeLoader } = useSplash();
  const { t } = useTranslation();

  const [leasingParameters, setLeasingParameters] = useState<LeasingParameter[]>();
  const [status, setStatus] = useState(Status.Idle);

  useEffect(() => {
    if (status === Status.Idle) {
      addLoader(SPLASH_KEY);
    } else if (status === Status.Success || status === Status.Error) {
      removeLoader(SPLASH_KEY);
    }
  }, [addLoader, removeLoader, status]);

  useEffect(() => {
    let abortFn: Function | undefined;
    let isRequestFinished = false;
    let isAborted = false;

    if (leasingParameters == null) {
      setStatus(Status.Loading);
      const { promise, abort } = hyperfetch<PaginatedList<LeasingParameter>>(
        `/api/leasing/parameters`,
        {
          method: 'GET'
        }
      );
      abortFn = abort;

      promise.then(({ data, error }: FetchValue<PaginatedList<LeasingParameter>>) => {
        isRequestFinished = true;
        if (isAborted) {
          return;
        } else if (data == null || error != null) {
          setStatus(Status.Error);
        } else if (data.totalCount === 0) {
          setStatus(Status.Error);
        } else {
          // Strip out the ones without any country being supported
          const [sanitizedLeasingParameters, leasingParametersWithoutCountries] = partition(
            data.results,
            ({ countries }) => !isEmpty(countries)
          );

          if (!isEmpty(leasingParametersWithoutCountries)) {
            message.warn(
              `${t('LEASING_PARAMETERS_WITHOUT_SUPPORTED_COUNTRIES_WARNING_MESSAGE')}${map(
                leasingParametersWithoutCountries,
                'title'
              ).join(', ')}`
            );
          }

          setLeasingParameters(sanitizedLeasingParameters);
          setStatus(Status.Success);
        }
      });
    }

    return () => {
      if (abortFn != null && isRequestFinished !== true) {
        console.log('Leasing parameters request canceled');
        isAborted = true;
        abortFn();
      }
    };
  }, [leasingParameters, t]);

  const value = useMemo(() => ({ leasingParameters, status }), [status, leasingParameters]);
  return <LeasingParametersContext.Provider value={value} {...props} />;
};

const useLeasingParameters = (): ContextValue => {
  const context = useContext(LeasingParametersContext);
  if (context === undefined) {
    throw new Error('useLeasingParameters must be used within an LeasingParametersProvider');
  }
  return context;
};

export { useLeasingParameters, LeasingParametersProvider };
