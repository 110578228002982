import { CashOutRow } from '@hypercharge/hyperleasing-commons/lib/types/leasing';
import * as Sentry from '@sentry/browser';
import { message, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { pick } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageHelmet from '../layout/PageHelmet';
import { FetchResponse, FetchValue, hyperfetch, json } from '../utils/httpClient';
import { Status } from '../utils/types';
import { CalculatorRequest } from './types';
import { stringifyPrice } from './utils';

type Props = {
  calculatorRequest: CalculatorRequest;
};

const CashOut = ({ calculatorRequest }: Props) => {
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const [cashOutRows, setCashOutRows] = useState<CashOutRow[]>();
  const [status, setStatus] = useState(Status.Idle);

  // Fetch cash-out rows
  React.useEffect(() => {
    setStatus(Status.Loading);
    const { promise } = getCashOutRows(
      pick(calculatorRequest, [
        'bankDeposit',
        'bankDurationInMonths',
        'bankInterestRatePercentage',
        'bankResidualValue',
        'durationInMonths',
        'includesAllRisks',
        'includesCivilLiability',
        'includesOneTimeRoadTax',
        'includesSpecialInsurance',
        'includesYearlyRoadTax',
        'insuranceAmount',
        'parameterId',
        'salesPrice'
      ])
    );
    promise
      .then(({ data, error }: FetchValue<CashOutRow[]>) => {
        if (error != null || data == null) {
          setStatus(Status.Error);
          Sentry.captureException(error);
          message.error(t('DATA_FETCH_FAILED'));
        } else {
          setCashOutRows(data);
          setStatus(Status.Success);
        }
      })
      .catch(() => {
        console.error('cash-out request canceled');
      });
  }, [t, setCashOutRows, calculatorRequest]);

  const renderPriceAmount = useCallback(
    (value: number, row: CashOutRow, index: number): React.ReactNode =>
      value == null || value === 0 ? null : <span>{stringifyPrice(value, 2, language)}</span>,
    [language]
  );

  const columns: ColumnProps<CashOutRow>[] = useMemo(
    () => [
      {
        key: 'time',
        dataIndex: 'time',
        title: '#'
      },
      {
        key: 'deposit',
        dataIndex: 'deposit',
        title: t('CALCULATOR_DEPOSIT'),
        render: renderPriceAmount
      },
      {
        key: 'residualValue',
        dataIndex: 'residualValue',
        title: t('CASH_IN_RESIDUAL_VALUE'),
        render: renderPriceAmount
      },
      {
        key: 'leasingAmount',
        dataIndex: 'leasingAmount',
        title: t('CASH_IN_LEASE_AMOUNT'),
        render: renderPriceAmount
      },
      {
        key: 'insurancePayment',
        dataIndex: 'insurancePayment',
        title: t('CASH_IN_INSURANCE_PAYMENT'),
        render: renderPriceAmount
      },
      {
        key: 'taxPayment',
        dataIndex: 'taxPayment',
        title: t('CASH_IN_TAX_PAYMENT'),
        render: renderPriceAmount
      },
      {
        key: 'cashOut',
        dataIndex: 'cashOut',
        title: t('CASH_OUT__TITLE'),
        render: renderPriceAmount
      },
      {
        key: 'cumulativeCash',
        dataIndex: 'cumulativeCash',
        title: t('CASH_IN_CUMULATIVE'),
        render: renderPriceAmount
      }
    ],
    [t, renderPriceAmount]
  );

  return (
    <>
      <PageHelmet pageTitle={t('CASH_OUT__TITLE')} />
      <Table<CashOutRow>
        rowKey="time"
        size="small"
        loading={status === Status.Loading}
        columns={columns}
        dataSource={cashOutRows}
        pagination={{ pageSize: 50 }}
      />
    </>
  );
};

export default CashOut;

//
// Utilies
//

const getCashOutRows = (cashOutInputValues: {
  bankDeposit: number;
  bankDurationInMonths: number;
  bankInterestRatePercentage: number;
  bankResidualValue: number;
  durationInMonths: number;
  includesAllRisks: boolean;
  includesCivilLiability: boolean;
  includesOneTimeRoadTax: boolean;
  includesSpecialInsurance: boolean;
  includesYearlyRoadTax: boolean;
  insuranceAmount: number;
  parameterId: string;
  salesPrice: number;
}): FetchResponse<CashOutRow[]> =>
  hyperfetch<CashOutRow[]>('/api/leasing/simulation/cash-out', {
    method: 'POST',
    body: json(cashOutInputValues)
  });
