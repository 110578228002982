import { Form, Input } from 'antd';
import { FieldProps } from 'formik';
import { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { ScError, ScInputLabel } from './utils';

type Props = {
  className?: string;
  label?: string;
  submitOnPaste?: boolean;
} & FieldProps<any>;

const FormInput = ({
  form: { errors, handleBlur, submitForm },
  field,
  label,
  submitOnPaste,
  className,
  ...props
}: Props) => {
  const inputRef = useRef<Input>(null);
  const didPaste = useRef<boolean>(false);

  const isError = useMemo(() => get(errors, field.name) != null, [errors, field]);
  const errorMessage = useMemo(
    () => (isError ? <ScError>{get(errors, field.name)}</ScError> : null),
    [isError, errors, field.name]
  );

  const onPaste = useCallback(() => {
    if (submitOnPaste) {
      didPaste.current = true;
    }
  }, [submitOnPaste]);

  useEffect(() => {
    if (submitOnPaste && didPaste.current && field.value) {
      submitForm();
    }
  }, [submitOnPaste, field.value, submitForm]);

  return (
    <>
      {label != null && <ScInputLabel>{label}</ScInputLabel>}
      <Form.Item
        hasFeedback={isError}
        help={errorMessage}
        validateStatus={isError ? 'error' : undefined}
        className={className}
        {...field}
      >
        <Input
          ref={inputRef}
          {...props}
          name={field.name}
          value={field.value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            field.onChange(field.name)(event.target.value);
          }}
          onBlur={handleBlur}
          onPaste={onPaste}
        />
      </Form.Item>
    </>
  );
};

export default FormInput;
