export const save = (localStorageKey: string, data: any | undefined): void => {
  if (data != null) {
    window.localStorage.setItem(localStorageKey, JSON.stringify(data));
  } else {
    window.localStorage.removeItem(localStorageKey);
  }
};

export const load = <T>(localStorageKey: string): T | undefined => {
  const dataStr = window.localStorage.getItem(localStorageKey);
  return dataStr ? JSON.parse(dataStr) : undefined;
};
