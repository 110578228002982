import { connect, FormikContext } from 'formik';
import { isEmpty, isEqual } from 'lodash';
import { useCallback, useEffect } from 'react';
import usePrevious from '../utils/usePrevious';

export const AutoUpdate = <TFormValues, TExtraProps>({
  formik: { values, setSubmitting, validateForm, setValues, submitForm },
  beforeUpdate
}: {
  formik: FormikContext<TFormValues>;
  beforeUpdate?: (values: TFormValues, setValues: (values: TFormValues) => void) => Promise<void>;
}) => {
  const previousValues = usePrevious(values);

  const handleUpdate = useCallback(async () => {
    if (previousValues && Object.keys(previousValues).length && !isEqual(previousValues, values)) {
      const errors = await validateForm(values);
      if (isEmpty(errors)) {
        setSubmitting(true);
        try {
          if (beforeUpdate) {
            await beforeUpdate(values, setValues);
          }
          submitForm();
        } catch {
          setSubmitting(false);
        }
      }
    }
  }, [validateForm, previousValues, values, beforeUpdate, setSubmitting, setValues, submitForm]);

  useEffect(() => {
    handleUpdate();
  }, [handleUpdate]);

  return null;
};

// https://github.com/jaredpalmer/formik/issues/843#issuecomment-539553386
export const withTypes = <
  TFormValues,
  TExtraProps = {
    beforeUpdate?: (values: TFormValues, setValues: (values: TFormValues) => void) => Promise<void>;
  }
>(
  WrappedComponent: React.ComponentType<
    TExtraProps & {
      formik: FormikContext<TFormValues>;
    }
  >
) => connect<TExtraProps, TFormValues>(WrappedComponent);
