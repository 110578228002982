import { CashInRow } from '@hypercharge/hyperleasing-commons/lib/types/leasing';
import * as Sentry from '@sentry/browser';
import { message, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { pick } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageHelmet from '../layout/PageHelmet';
import { FetchResponse, FetchValue, hyperfetch, json } from '../utils/httpClient';
import { Status } from '../utils/types';
import { CalculatorRequest } from './types';
import { stringifyPrice } from './utils';

type Props = {
  calculatorRequest: CalculatorRequest;
};

const CashIn = ({ calculatorRequest }: Props) => {
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const [cashInRows, setCashInRows] = useState<CashInRow[]>();
  const [status, setStatus] = useState(Status.Idle);

  // Fetch cash-in rows
  React.useEffect(() => {
    setStatus(Status.Loading);
    const { promise } = getCashInRows(
      pick(calculatorRequest, [
        'deposit',
        'durationInMonths',
        'includesAllRisks',
        'includesCivilLiability',
        'includesOneTimeRoadTax',
        'includesSpecialInsurance',
        'includesYearlyRoadTax',
        'insuranceAmount',
        'interestRatePercentage',
        'marginPercentage',
        'monthlyAdministrationFee',
        'parameterId',
        'residualValue',
        'salesPrice',
        'startupFeePercentage'
      ])
    );
    promise
      .then(({ data, error }: FetchValue<CashInRow[]>) => {
        if (error != null || data == null) {
          setStatus(Status.Error);
          Sentry.captureException(error);
          message.error(t('DATA_FETCH_FAILED'));
        } else {
          setCashInRows(data);
          setStatus(Status.Success);
        }
      })
      .catch(() => {
        console.error('cash-in request canceled');
      });
  }, [t, setCashInRows, calculatorRequest]);

  const renderPriceAmount = useCallback(
    (value: number, row: CashInRow, index: number): React.ReactNode =>
      value == null || value === 0 ? null : <span>{stringifyPrice(value, 2, language)}</span>,
    [language]
  );

  const columns: ColumnProps<CashInRow>[] = useMemo(
    () => [
      {
        key: 'time',
        dataIndex: 'time',
        title: '#'
      },
      {
        key: 'deposit',
        dataIndex: 'deposit',
        title: t('CALCULATOR_DEPOSIT'),
        render: renderPriceAmount
      },
      {
        key: 'monthlyAdministrationFee',
        dataIndex: 'monthlyAdministrationFee',
        title: t('CASH_IN_ADMINISTRATION_FEE'),
        render: renderPriceAmount
      },
      {
        key: 'startupFee',
        dataIndex: 'startupFee',
        title: t('CALCULATOR_START_UP_FEE'),
        render: renderPriceAmount
      },
      {
        key: 'residualValue',
        dataIndex: 'residualValue',
        title: t('CASH_IN_RESIDUAL_VALUE'),
        render: renderPriceAmount
      },
      {
        key: 'leasingAmountWithoutAdminFee',
        dataIndex: 'leasingAmountWithoutAdminFee',
        title: t('CASH_IN_LEASE_AMOUNT'),
        render: renderPriceAmount
      },
      {
        key: 'insurancePayment',
        dataIndex: 'insurancePayment',
        title: t('CASH_IN_INSURANCE_PAYMENT'),
        render: renderPriceAmount
      },
      {
        key: 'taxPayment',
        dataIndex: 'taxPayment',
        title: t('CASH_IN_TAX_PAYMENT'),
        render: renderPriceAmount
      },
      {
        key: 'cashIn',
        dataIndex: 'cashIn',
        title: t('CASH_IN__TITLE'),
        render: renderPriceAmount
      },
      {
        key: 'cumulativeCash',
        dataIndex: 'cumulativeCash',
        title: t('CASH_IN_CUMULATIVE'),
        render: renderPriceAmount
      }
    ],
    [t, renderPriceAmount]
  );

  return (
    <>
      <PageHelmet pageTitle={t('CASH_IN__TITLE')} />
      <Table<CashInRow>
        rowKey="time"
        size="small"
        loading={status === Status.Loading}
        columns={columns}
        dataSource={cashInRows}
        pagination={{ pageSize: 50 }}
      />
    </>
  );
};

export default CashIn;

//
// Utilies
//

const getCashInRows = (cashInInputValues: {
  deposit: number;
  durationInMonths: number;
  includesAllRisks: boolean;
  includesCivilLiability: boolean;
  includesOneTimeRoadTax: boolean;
  includesSpecialInsurance: boolean;
  includesYearlyRoadTax: boolean;
  insuranceAmount: number;
  interestRatePercentage: number;
  marginPercentage: number;
  monthlyAdministrationFee: number;
  parameterId: string;
  residualValue: number;
  salesPrice: number;
  startupFeePercentage: number;
}): FetchResponse<CashInRow[]> =>
  hyperfetch<CashInRow[]>('/api/leasing/simulation/cash-in', {
    method: 'POST',
    body: json(cashInInputValues)
  });
