import React, { PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { FetchValue, hyperfetch } from '../../utils/httpClient';
import { Status } from '../../utils/types';
import { useAuth } from '../auth/AuthProvider';
import { useSplash } from '../splash/SplashProvider';

export type User = {
  [key: string]: any;
  entityId: string;
  firstName: string;
  lastName: string;
  emailWork: string;
  groups: string[];
};

const SPLASH_KEY = 'user-provider';

type ContextValue = { user?: User; status: Status };

const UserContext = React.createContext<ContextValue | undefined>(undefined);

const UserProvider = (props: PropsWithChildren<any>) => {
  const { addLoader, removeLoader } = useSplash();
  const auth = useAuth();

  const [user, setUser] = useState<User>();
  const [status, setStatus] = useState(Status.Idle);

  if (status !== Status.Success) {
    addLoader(SPLASH_KEY);
  }

  useEffect(() => {
    if (status === Status.Success) {
      removeLoader(SPLASH_KEY);
    }
  }, [removeLoader, status]);

  useEffect(() => {
    let abortFn: Function | undefined;
    let isRequestFinished = false;
    let isAborted = false;

    const allowGetUser =
      auth.authData != null && (user == null || user.entityId !== auth.authData.userId);

    if (allowGetUser) {
      setStatus(Status.Loading);
      const { promise, abort } = hyperfetch<User>(`/api/cms/account/me`, {
        method: 'GET'
      });
      abortFn = abort;

      promise.then(({ data, error }: FetchValue<User>) => {
        isRequestFinished = true;
        if (isAborted) {
          return;
        } else if (data == null || error != null) {
          setStatus(Status.Error);
        } else {
          setUser(data);
          setStatus(Status.Success);
        }
      });
    }

    return () => {
      if (abortFn != null && isRequestFinished !== true) {
        console.log('User request canceled');
        isAborted = true;
        abortFn();
      }
    };
  }, [auth, user]);

  const value = useMemo(() => ({ user, status }), [status, user]);
  return <UserContext.Provider value={value} {...props} />;
};

const useUser = (): ContextValue => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within an UserProvider');
  }
  return context;
};

export { useUser, UserProvider };
