import { message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type OnlineInfo = {
  isOnline: boolean;
};

const getOnlineStatus = (): boolean => (window.navigator && window.navigator.onLine ? true : false);

const OnlineContext = React.createContext<OnlineInfo | undefined>(undefined);

const OnlineProvider = (props: any) => {
  const { t } = useTranslation();

  const [onlineInfo, setOnlineInfo] = useState<OnlineInfo>({
    isOnline: getOnlineStatus()
  });

  useEffect(() => {
    const handleChange = () => {
      const isOnline = getOnlineStatus();
      setOnlineInfo({ isOnline });
      if (isOnline) {
        message.success(t('ONLINE_MESSAGE'));
      } else {
        message.warning(t('OFFLINE_ERROR_MESSAGE'));
      }
    };

    window.addEventListener('offline', handleChange, false);
    window.addEventListener('online', handleChange, false);

    return () => {
      window.removeEventListener('online', handleChange);
      window.removeEventListener('offline', handleChange);
    };
  }, [t]);

  return <OnlineContext.Provider value={onlineInfo} {...props} />;
};

const useOnline = (): OnlineInfo => {
  const context = useContext(OnlineContext);
  if (context === undefined) {
    throw new Error('useOnline must be used within an OnlineProvider');
  }
  return context;
};

export { useOnline, OnlineProvider };
