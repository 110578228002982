import { InputNumber } from 'antd';
import { FieldProps } from 'formik';
import { get } from 'lodash';
import React, { useMemo, useRef } from 'react';
import styled from 'styled-components/macro';
import { ScError, ScFormItem, ScInputLabel } from './utils';

const ScInputNumber = styled(InputNumber)`
  &.ant-input-number {
    width: 100%;
  }
`;

type Props = {
  className?: string;
  label?: string;
  disabled?: boolean;
} & FieldProps<any>;

const FormNumberInput = ({
  form: { errors, handleBlur },
  field,
  label,
  className,
  ...props
}: Props) => {
  const inputRef = useRef<InputNumber>(null);

  const isError = useMemo(() => get(errors, field.name) != null, [errors, field]);
  const errorMessage = useMemo(
    () => (isError ? <ScError>{get(errors, field.name)}</ScError> : null),
    [isError, errors, field.name]
  );

  return (
    <>
      {label != null && <ScInputLabel>{label}</ScInputLabel>}
      <ScFormItem
        hasFeedback={isError}
        help={errorMessage}
        validateStatus={isError ? 'error' : undefined}
        className={className}
        {...field}
      >
        <ScInputNumber
          ref={inputRef}
          {...props}
          name={field.name}
          value={field.value}
          onChange={(value: number | undefined) => {
            field.onChange(field.name)(value);
          }}
          onBlur={handleBlur}
        />
      </ScFormItem>
    </>
  );
};

export default FormNumberInput;
