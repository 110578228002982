import { Divider, Icon, Layout } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useAuth } from '../context/auth/AuthProvider';
import { useUser } from '../context/user/UserProvider';
import { APP_NAME, BREAKPOINTS } from '../utils/constants';
import { ScWrapper } from './styles';

const { Header } = Layout;

const ScHeader = styled(Header)`
  background: #162133 !important;
  border-bottom: 1px solid lightgray;
  padding: 0 !important;

  @media print {
    display: none !important;
  }
`;
const ScFlexbox = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0 5px;
  flex-direction: row;
`;
const ScAppName = styled.div`
  flex: 1;
  margin-left: 1rem;
  font-size: 1.5rem;
  color: #fafafa;
`;
const ScRightSection = styled.div`
  flex: initial;
  color: #fafafa;
  margin-right: 1rem;

  div {
    display: inline-block;
  }
`;
const ScHideOnMobile = styled.div`
  @media (max-width: ${BREAKPOINTS.sm}px) {
    display: none !important;
  }
`;
const ScIcon = styled(Icon)`
  margin-right: 0.5rem;
`;
const ScDivider = styled(Divider)`
  background-color: #fafafa;
`;
const ScLogout = styled.div`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const AppHeader = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { logout } = useAuth();
  const [loggingOut, setLoggingOut] = useState<boolean>(false);
  const unmounted = useRef<boolean>(false);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const handleLogout = useCallback(async () => {
    setLoggingOut(true);
    await logout();
    if (!unmounted.current) {
      setLoggingOut(false);
    }
  }, [logout]);

  return (
    <ScHeader>
      <ScWrapper>
        <ScFlexbox>
          <ScAppName>
            <img src="/favicon-32x32.png" alt="logo" /> {APP_NAME}
          </ScAppName>
          <ScRightSection>
            <ScHideOnMobile>
              <ScIcon type="user" />
              <div>{user == null ? '-' : user.title}</div>
              <ScDivider type="vertical" />
            </ScHideOnMobile>
            <ScIcon type={loggingOut ? 'loading' : 'logout'} />
            <ScLogout onClick={handleLogout}>{t('AUTH__SIGN_OUT')}</ScLogout>
          </ScRightSection>
        </ScFlexbox>
      </ScWrapper>
    </ScHeader>
  );
};

export default AppHeader;
