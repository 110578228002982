import React from 'react';
import Helmet from 'react-helmet';
import { useTenant } from '../context/tenant/TenantProvider';
import { APP_NAME } from '../utils/constants';

type Props = {
  pageTitle: string;
};

const PageHelmet = ({ pageTitle }: Props) => {
  const { tenantId } = useTenant();
  return <Helmet title={`[${tenantId}] ${APP_NAME}: ${pageTitle}`} />;
};

export default PageHelmet;
