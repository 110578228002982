// Source: https://github.com/react-ga/react-ga/issues/122#issuecomment-521781395

import { Location, LocationListener, UnregisterCallback } from 'history';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { RouteComponentProps, withRouter } from 'react-router';
import { useTenant } from '../tenant/TenantProvider';

const sendPageView: LocationListener = (location: Location): void => {
  const page = location.pathname + location.search;
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

type Props = {
  children: JSX.Element;
} & RouteComponentProps;

const AnalyticsTracking = ({ children, history }: Props): JSX.Element => {
  const { tenantId } = useTenant();

  useEffect((): UnregisterCallback | void => {
    if (process.env.REACT_APP_GA_TRACKING_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
      ReactGA.set({ dimension1: tenantId });
      sendPageView(history.location, 'REPLACE');
      return history.listen(sendPageView);
    }
  }, [history, tenantId]);

  return children;
};

export default withRouter(AnalyticsTracking);
