import React, { ReactNode } from 'react';
import { Loading } from '../../components/Loading';
import { useSplash } from './SplashProvider';

type Props = {
  loaders?: string[];
  children: ReactNode;
};

export const Splash = (props: Props) => {
  const { getIsReady } = useSplash();
  return getIsReady(props.loaders) ? <>{props.children}</> : <Loading />;
};
