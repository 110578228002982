import React, { useContext, useState } from 'react';

type ContextValue = {
  tenantId: string;
};

const getTenantId = (): string => {
  const subdomainRegex = /([a-zA-Z0-9-]{1,61})\.[a-zA-Z0-9-]{1,61}\.[a-zA-Z]{2,}$/i;
  const matches = window.location.hostname.match(subdomainRegex);
  if (matches != null && matches.length > 1) {
    return matches[1]; // eg. 'man-it.example.com' returns 'man-it'
  }

  if (!process.env.REACT_APP_DEFAULT_TENANT_ID) {
    throw new Error('Cannot determine tenantId');
  }

  // Used in development
  return process.env.REACT_APP_DEFAULT_TENANT_ID;
};

const TenantContext = React.createContext<ContextValue | undefined>(undefined);

const TenantProvider = ({ children }: any) => {
  const [tenantId] = useState(getTenantId);

  return <TenantContext.Provider value={{ tenantId }}>{children}</TenantContext.Provider>;
};

const useTenant = (): ContextValue => {
  const context = useContext(TenantContext);
  if (context === undefined) {
    throw new Error('useTenant must be used within an TenantProvider');
  }
  return context;
};

export { useTenant, TenantProvider };
