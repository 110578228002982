import { Layout } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import styled from 'styled-components/macro';
import ErrorBoundary from '../components/ErrorBoundary';
import { UnauthenticatedAppProviders } from '../context';
import { useAuth } from '../context/auth/AuthProvider';
import EmailCodeForm from '../login/LoginCodeForm';
import LoginEmailForm from '../login/LoginEmailForm';
import Footer from './Footer';
import PageHelmet from './PageHelmet';

const { Content } = Layout;

const ScLayout = styled(Layout)`
  min-height: 100vh;
`;

const UnauthenticatedApp = ({ location }: RouteComponentProps<any>) => {
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const { from } = location.state || { from: { pathname: '/' } };

  return !isAuthenticated ? (
    <UnauthenticatedAppProviders>
      <ScLayout>
        <PageHelmet pageTitle={t('AUTH__SIGN_IN')} />
        <Content>
          <ErrorBoundary>
            <Switch>
              <Route path="/login/code" component={EmailCodeForm} />
              <Route path="/login" component={LoginEmailForm} />
              <Redirect to="/login" />
            </Switch>
          </ErrorBoundary>
        </Content>
        <Footer />
      </ScLayout>
    </UnauthenticatedAppProviders>
  ) : (
    <Redirect to={from} />
  );
};

export default UnauthenticatedApp;
