import { LEASING_CONTRACT_PROCESS_DEFINITION_ID } from '@hypercharge/hyperleasing-commons/lib/constants';
import { CalculatorResponse } from '@hypercharge/hyperleasing-commons/lib/types/leasing';
import * as Sentry from '@sentry/browser';
import { Button, Icon, message, notification, Tabs } from 'antd';
import { Formik, FormikActions, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import { useLeasingParameters } from '../context/leasing-parameters/LeasingParametersProvider';
import { useTenant } from '../context/tenant/TenantProvider';
import ErrorPage from '../layout/ErrorPage';
import {
  CALCULATOR_DEFAULT_ADMINISTRATION_FEE,
  CALCULATOR_DEFAULT_BANK_DEPOSIT,
  CALCULATOR_DEFAULT_BANK_DURATION_IN_MONTHS,
  CALCULATOR_DEFAULT_BANK_INTEREST_PERCENTAGE,
  CALCULATOR_DEFAULT_BANK_RESIDUAL_VALUE,
  CALCULATOR_DEFAULT_DEPOSIT,
  CALCULATOR_DEFAULT_DURATION_MONTHS,
  CALCULATOR_DEFAULT_INCLUDES_ALL_RISKS,
  CALCULATOR_DEFAULT_INCLUDES_CIVIL_LIABILITY,
  CALCULATOR_DEFAULT_INCLUDES_ONE_TIME_ROAD_TAX,
  CALCULATOR_DEFAULT_INCLUDES_SPECIAL_INSURANCE,
  CALCULATOR_DEFAULT_INCLUDES_YEARLY_ROAD_TAX,
  CALCULATOR_DEFAULT_INSURANCE_AMOUNT,
  CALCULATOR_DEFAULT_INTEREST_PERCENTAGE,
  CALCULATOR_DEFAULT_MARGIN_PERCENTAGE,
  CALCULATOR_DEFAULT_PURCHASE_PRICE,
  CALCULATOR_DEFAULT_RESIDUAL_VALUE,
  CALCULATOR_DEFAULT_SALES_PRICE,
  CALCULATOR_DEFAULT_STARTUP_FEE_PERCENTAGE
} from '../utils/constants';
import { FetchResponse, FetchValue, hyperfetch, json } from '../utils/httpClient';
import { Status } from '../utils/types';
import useDebounce from '../utils/useDebounce';
import CalculatorForm from './CalculatorForm';
import CashIn from './CashIn';
import CashOut from './CashOut';
import { CalculatorInputValues, FormValues } from './types';

enum TabKey {
  CalculatorKey = 'CALCULATOR',
  CashInKey = 'CASH_IN',
  CashOutKey = 'CASH_OUT'
}

const CalculatorPage = () => {
  const { t } = useTranslation();
  const { tenantId } = useTenant();
  const { leasingParameters, status } = useLeasingParameters();

  const [activeTab, setActiveTab] = useState<string>(TabKey.CalculatorKey);
  const [calculations, setCalculations] = React.useState<CalculatorResponse>();
  const [startProcessFetchResponse, setStartProcessFetchResponse] = useState<FetchResponse<
    string
  > | null>();

  const initialFormValues: FormValues = useMemo(
    () => ({
      countryCode:
        leasingParameters && status === Status.Success
          ? leasingParameters[0].countries[0].entityId
          : '', // If leasingParameters doesn't exist, an error page will rendered. If it does exist, the nested props are guaranteed to exist by the LeasingParametersProvider
      calculatorRequest: {
        parameterId:
          leasingParameters && status === Status.Success ? leasingParameters[0].entityId : '', // If leasingParameters doesn't exist, an error page will rendered
        salesPrice: CALCULATOR_DEFAULT_SALES_PRICE,
        purchasePrice: CALCULATOR_DEFAULT_PURCHASE_PRICE,
        deposit: CALCULATOR_DEFAULT_DEPOSIT,
        durationInMonths: CALCULATOR_DEFAULT_DURATION_MONTHS,
        monthlyAdministrationFee: CALCULATOR_DEFAULT_ADMINISTRATION_FEE,
        startupFeePercentage: CALCULATOR_DEFAULT_STARTUP_FEE_PERCENTAGE,
        residualValue: CALCULATOR_DEFAULT_RESIDUAL_VALUE,
        interestRatePercentage: CALCULATOR_DEFAULT_INTEREST_PERCENTAGE,
        bankDurationInMonths: CALCULATOR_DEFAULT_BANK_DURATION_IN_MONTHS,
        bankResidualValue: CALCULATOR_DEFAULT_BANK_RESIDUAL_VALUE,
        bankDeposit: CALCULATOR_DEFAULT_BANK_DEPOSIT,
        bankInterestRatePercentage: CALCULATOR_DEFAULT_BANK_INTEREST_PERCENTAGE,
        insuranceAmount: CALCULATOR_DEFAULT_INSURANCE_AMOUNT,
        marginPercentage: CALCULATOR_DEFAULT_MARGIN_PERCENTAGE,
        includesCivilLiability: CALCULATOR_DEFAULT_INCLUDES_CIVIL_LIABILITY,
        includesSpecialInsurance: CALCULATOR_DEFAULT_INCLUDES_SPECIAL_INSURANCE,
        includesAllRisks: CALCULATOR_DEFAULT_INCLUDES_ALL_RISKS,
        includesYearlyRoadTax: CALCULATOR_DEFAULT_INCLUDES_YEARLY_ROAD_TAX,
        includesOneTimeRoadTax: CALCULATOR_DEFAULT_INCLUDES_ONE_TIME_ROAD_TAX
      }
    }),
    [leasingParameters, status]
  );

  const fetchCalculations = useCallback(
    async (formValues: FormValues) => {
      const { promise } = getLeasingCalculation(formValues.calculatorRequest);
      const { data, error } = await promise;
      if (error != null || data == null) {
        Sentry.captureException(error);
        message.error(t('CALCULATOR__CALCULATION_RESPONSE_ERROR'));
      } else {
        setCalculations(data);
      }
    },
    [t, setCalculations]
  );

  const debouncedFetchCalculations = useDebounce(fetchCalculations, 350);

  // Fetch calculations at init
  useEffect(() => {
    if (leasingParameters != null) {
      fetchCalculations(initialFormValues);
    }
  }, [fetchCalculations, initialFormValues, leasingParameters]);

  const calculateValues = useCallback(
    async (values: FormValues, actions: FormikActions<FormValues>) => {
      await debouncedFetchCalculations(values);
      actions.setSubmitting(false);
    },
    [debouncedFetchCalculations]
  );

  const formSchema = useMemo(() => {
    const numericYup = Yup.string()
      .matches(/^([0]([.][0-9]+)?|[1-9]([0-9]+)?([.][0-9]+)?)$/, t('FORM__NUMERIC'))
      .required(t('FORM__REQUIRED'));
    return Yup.object().shape({
      countryCode: Yup.string().required(t('FORM__REQUIRED')),
      calculatorRequest: Yup.object().shape({
        parameterId: Yup.string().required(t('FORM__REQUIRED')),
        salesPrice: numericYup,
        purchasePrice: numericYup,
        deposit: numericYup,
        durationInMonths: numericYup,
        monthlyAdministrationFee: numericYup,
        startupFeePercentage: numericYup,
        residualValue: numericYup,
        interestRatePercentage: numericYup,
        bankDurationInMonths: numericYup,
        bankResidualValue: numericYup,
        bankDeposit: numericYup,
        bankInterestRatePercentage: numericYup,
        insuranceAmount: numericYup,
        marginPercentage: numericYup,
        includesCivilLiability: Yup.boolean(),
        includesSpecialInsurance: Yup.boolean(),
        includesAllRisks: Yup.boolean(),
        includesYearlyRoadTax: Yup.boolean()
      })
    });
  }, [t]);

  const createContractRequest = useCallback((formValues: FormValues) => {
    const startContractProcessFetchResponse = hyperfetch<string>(
      '/api/leasing/start-contract-process',
      {
        method: 'POST',
        body: json(formValues.calculatorRequest)
      }
    );
    setStartProcessFetchResponse(startContractProcessFetchResponse);
  }, []);

  useEffect(() => {
    let isRequestFinished = false;
    let isAborted = false;

    if (startProcessFetchResponse != null) {
      startProcessFetchResponse.promise
        .then(({ data: startedProcessEntityId, error }: FetchValue<string>) => {
          isRequestFinished = true;
          if (isAborted) {
            return;
          } else if (startedProcessEntityId == null || error != null) {
            message.error(t('CREATE_CONTRACT_ERROR'));
          } else {
            notification.success({
              message: t('MESSAGE__SUCCESS'),
              duration: null,
              description: (
                <>
                  {t('CREATE_CONTRACT_SUCCESS_MESSAGE')}
                  <ScProcessLink
                    href={`https://${tenantId}.${process.env.REACT_APP_HYPER_PORTAL_DOMAIN}/workflows/processes/${LEASING_CONTRACT_PROCESS_DEFINITION_ID}/browse/${startedProcessEntityId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('CREATE_CONTRACT_SUCCESS_PROCESS')} {startedProcessEntityId}
                  </ScProcessLink>
                </>
              )
            });
          }
        })
        .finally(() => {
          if (!isAborted) {
            setStartProcessFetchResponse(null);
          }
        });
    }

    return () => {
      if (
        startProcessFetchResponse != null &&
        startProcessFetchResponse.abort != null &&
        isRequestFinished !== true
      ) {
        isAborted = true;
        startProcessFetchResponse.abort();
      }
    };
  }, [t, tenantId, startProcessFetchResponse]);

  if (status === Status.Error) {
    return (
      <ErrorPage title={t('SORRY')} imageUrl="/images/notSupported.png">
        {t('CALCULATOR__INITIALIZATION_ERROR')}
      </ErrorPage>
    );
  }

  return (
    <ScContainer>
      <Formik
        initialValues={initialFormValues}
        onSubmit={calculateValues}
        validateOnBlur={false}
        validateOnChange={true}
        validationSchema={formSchema}
      >
        {({ dirty, validateForm, values, handleSubmit }: FormikProps<FormValues>) => {
          const runIfFormIsValid = async (callback: () => void) => {
            if (dirty) {
              const errors = await validateForm();
              if (isEmpty(errors)) {
                callback();
              } else {
                message.error(t('CALCULATOR__FILL_IN_ALLOWED_VALUES'));
              }
            } else {
              callback();
            }
          };
          return (
            <ScTabs
              size="large"
              onChange={(newTab: string) => {
                runIfFormIsValid(() => {
                  setActiveTab(newTab);
                });
              }}
              activeKey={activeTab}
              tabBarExtraContent={
                <Button
                  type="primary"
                  icon="plus"
                  loading={startProcessFetchResponse != null}
                  onClick={() => {
                    runIfFormIsValid(() => {
                      createContractRequest(values);
                    });
                  }}
                >
                  {t('CREATE_CONTRACT_BUTTON')}
                </Button>
              }
            >
              <TabPane
                tab={
                  <span>
                    <Icon type="calculator" theme="twoTone" />
                    {t('CALCULATOR__TITLE')}
                  </span>
                }
                key={TabKey.CalculatorKey}
              >
                <CalculatorForm
                  calculations={calculations}
                  disabled={startProcessFetchResponse != null}
                  formValues={values}
                  handleSubmit={handleSubmit}
                />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <Icon type="down-square" theme="twoTone" />
                    {t('CASH_IN__TITLE')}
                  </span>
                }
                key={TabKey.CashInKey}
              >
                {activeTab === TabKey.CashInKey && (
                  <CashIn calculatorRequest={values.calculatorRequest} />
                )}
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <Icon type="up-square" theme="twoTone" />
                    {t('CASH_OUT__TITLE')}
                  </span>
                }
                key={TabKey.CashOutKey}
              >
                {activeTab === TabKey.CashOutKey && (
                  <CashOut calculatorRequest={values.calculatorRequest} />
                )}
              </TabPane>
            </ScTabs>
          );
        }}
      </Formik>
    </ScContainer>
  );
};

export default CalculatorPage;

//
// Utilities
//

const { TabPane } = Tabs;

const ScContainer = styled.div`
  padding: 1rem;

  @media print {
    padding: 0;
  }
`;
const ScProcessLink = styled.a`
  margin-left: 0.5rem;
`;
const ScTabs = styled(Tabs)`
  @media print {
    .ant-tabs-bar {
      display: none !important;
    }
  }
`;

const getLeasingCalculation = (
  calculatorInputValues: CalculatorInputValues
): FetchResponse<CalculatorResponse> =>
  hyperfetch<CalculatorResponse>('/api/leasing/simulation/calculator', {
    method: 'POST',
    body: json(calculatorInputValues)
  });
