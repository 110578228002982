import React, { PropsWithChildren, useContext, useMemo, useState } from 'react';

type ContextValue = {
  email?: string;
  code?: string;
  setEmail: (email: string) => void;
  setCode: (email: string) => void;
};

const LoginDataContext = React.createContext<ContextValue | undefined>(undefined);

const LoginDataProvider = (props: PropsWithChildren<any>) => {
  const [email, setEmail] = useState();
  const [code, setCode] = useState();

  const value = useMemo(
    () => ({
      email,
      code,
      setEmail,
      setCode
    }),
    [code, email]
  );
  return <LoginDataContext.Provider value={value} {...props} />;
};

const useLoginData = (): ContextValue => {
  const context = useContext(LoginDataContext);
  if (context === undefined) {
    throw new Error('useLoginData must be used within an LoginDataProvider');
  }
  return context;
};

export { useLoginData, LoginDataProvider };
