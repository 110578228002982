import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorPage from './ErrorPage';

const NoPermissions = () => {
  const { t } = useTranslation();
  return (
    <ErrorPage title={t('SORRY')} imageUrl="/images/notSupported.png">
      {t('NO_PERMISSIONS_DESCRIPTION')}
    </ErrorPage>
  );
};

export default NoPermissions;
