import {
  LEASING_ADMIN_PERMISSION,
  LEASING_USER_PERMISSION
} from '@hypercharge/hyperleasing-commons/lib/constants';
import { Layout } from 'antd';
import { intersection, isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import styled from 'styled-components/macro';
import CalculatorPage from '../calculator/CalculatorPage';
import ErrorBoundary from '../components/ErrorBoundary';
import { AuthenticatedAppProviders } from '../context';
import { useAuth } from '../context/auth/AuthProvider';
import AppHeader from './AppHeader';
import Footer from './Footer';
import NoPermissions from './NoPermissions';
import NotFound from './NotFound';
import { ScWrapper } from './styles';

const { Content } = Layout;

const ScLayout = styled(Layout)`
  min-height: 100vh !important;
  position: relative !important;
`;

const AuthenticatedApp = ({ location }: RouteComponentProps<any>) => {
  const { isAuthenticated, authData } = useAuth();

  const userHasLeasingPermissions = useMemo(
    () =>
      !isEmpty(
        intersection(authData ? authData.tenantPermissions : [], [
          LEASING_USER_PERMISSION,
          LEASING_ADMIN_PERMISSION
        ])
      ),
    [authData]
  );

  return isAuthenticated ? (
    <AuthenticatedAppProviders>
      <ScLayout>
        <Layout>
          <AppHeader />
          <Content>
            <ErrorBoundary>
              <ScWrapper>
                {userHasLeasingPermissions ? (
                  <Switch>
                    <Route exact path="/calculator" component={CalculatorPage} />
                    <Redirect exact from="/" to="/calculator" />
                    <Route component={NotFound} />
                  </Switch>
                ) : (
                  <NoPermissions />
                )}
              </ScWrapper>
            </ErrorBoundary>
          </Content>
          <Footer />
        </Layout>
      </ScLayout>
    </AuthenticatedAppProviders>
  ) : (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: location }
      }}
    />
  );
};

export default AuthenticatedApp;
