import * as Sentry from '@sentry/browser';
import { ConfigProvider } from 'antd';
import { Locale } from 'antd/es/locale-provider';
import enUS from 'antd/es/locale/en_US';
import frFR from 'antd/es/locale/fr_FR';
import nlBE from 'antd/es/locale/nl_BE';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  children: JSX.Element;
};

const AntI18nProvider = ({ children }: Props): JSX.Element => {
  const {
    i18n: { language }
  } = useTranslation();
  const [locale, setLocale] = useState<Locale>(enUS);

  useEffect(() => {
    switch (language) {
      case 'fr':
        setLocale(frFR);
        return;
      case 'nl':
        setLocale(nlBE);
        return;
      default:
        Sentry.captureMessage(
          `Locale for antd not provided for ${language}, so English was set instead`
        );
        setLocale(enUS);
    }
  }, [language]);

  return <ConfigProvider locale={locale}>{children}</ConfigProvider>;
};

export default AntI18nProvider;
