import { Form } from 'antd';
import styled from 'styled-components/macro';

export const ScInputLabel = styled.div`
  padding: 6px 0px 7px;
  color: rgb(140, 140, 140);
  font-size: 0.9rem;
  line-height: 0.9rem;
  font-weight: 400;
`;

export const ScError = styled.div`
  color: ${props => props.theme.colors.error};
`;

export const ScFormItem = styled(Form.Item)`
  && {
    margin-bottom: 0;
  }
`;
