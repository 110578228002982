import { LEASING_ADMIN_PERMISSION } from '@hypercharge/hyperleasing-commons/lib/constants';
import { OptionT } from '@hypercharge/hyperleasing-commons/lib/types/common';
import { CalculatorResponse } from '@hypercharge/hyperleasing-commons/lib/types/leasing';
import { Card, Col, Row } from 'antd';
import { FastField } from 'formik';
import { find, flatMap, map, memoize, orderBy, uniqBy } from 'lodash';
import React, { CSSProperties, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useLeasingParameters } from '../context/leasing-parameters/LeasingParametersProvider';
import { useUser } from '../context/user/UserProvider';
import { AutoUpdate, withTypes } from '../forms/AutoUpdate';
import FormCheckbox from '../forms/FormCheckbox';
import FormNumberInput from '../forms/FormNumberInput';
import FormRadio from '../forms/FormRadio';
import FormSelect from '../forms/FormSelect';
import FormSlider from '../forms/FormSlider';
import { ScInputLabel } from '../forms/utils';
import PageHelmet from '../layout/PageHelmet';
import { theme } from '../theme/theme';
import {
  BREAKPOINTS,
  CALCULATOR_BANK_DURATION_IN_MONTHS_SLIDER_MAX,
  CALCULATOR_BANK_DURATION_IN_MONTHS_SLIDER_MIN,
  CALCULATOR_BANK_DURATION_IN_MONTHS_SLIDER_STEP,
  CALCULATOR_DURATION_IN_MONTHS_SLIDER_MAX,
  CALCULATOR_DURATION_IN_MONTHS_SLIDER_MAX_ADMINS,
  CALCULATOR_DURATION_IN_MONTHS_SLIDER_MIN,
  CALCULATOR_DURATION_IN_MONTHS_SLIDER_STEP,
  CALCULATOR_DURATION_IN_MONTHS_SLIDER_STEP_ADMINS
} from '../utils/constants';
import { FormValues } from './types';
import { stringifyPrice } from './utils';

enum ColorStatus {
  Default,
  Error,
  Success
}

type Props = {
  calculations: CalculatorResponse | undefined;
  disabled: boolean;
  formValues: FormValues;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
};

const CalculatorForm = ({
  calculations,
  disabled,
  formValues: { countryCode, calculatorRequest },
  handleSubmit
}: Props) => {
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const { leasingParameters } = useLeasingParameters();
  const { user } = useUser();

  if (leasingParameters == null || user == null) {
    throw Error(t('CALCULATOR__INITIALIZATION_ERROR'));
  }

  const formatDurationInMonths = React.useCallback(
    (durationInMonths: number) => `${durationInMonths} ${t('CALCULATOR_MONTHS')}`,
    [t]
  );

  const getVehicleTypeOptions = useCallback(
    (countryCode: string): OptionT[] => {
      let options: OptionT[] = [];
      leasingParameters
        .filter(({ countries }) => map(countries, 'entityId').includes(countryCode))
        .forEach(leasingParameter => {
          let newOption: OptionT = {
            value: leasingParameter.entityId,
            label: leasingParameter.title || leasingParameter.entityId
          };

          options.push(newOption);
        });
      options = uniqBy(options, 'value');
      return orderBy<OptionT>(options, ['label'], ['asc']);
    },
    [leasingParameters]
  );

  const countryCodeOptions: OptionT[] = useMemo(() => {
    let options: OptionT[] = [];
    leasingParameters.forEach(({ countries }) => {
      if (countries != null) {
        options = [
          ...options,
          ...countries.map(({ entityId, title }) => ({
            value: entityId,
            label: title || entityId
          }))
        ];
      }
    });
    options = uniqBy(options, 'value');
    return orderBy<OptionT>(options, ['label'], ['asc']);
  }, [leasingParameters]);

  const isAdmin = useMemo(() => {
    const userPermissions = flatMap(user.groups, 'groupPermissions');
    return userPermissions.includes(LEASING_ADMIN_PERMISSION);
  }, [user]);

  const beforeUpdateFormValues = React.useCallback(
    (values: FormValues, setValues: (values: FormValues) => void): Promise<void> =>
      new Promise((resolve, reject) => {
        // Fix incorrect combination of parameter/country
        const supportedLeasingParametersIds = map(
          leasingParameters.filter(
            ({ countries }) => !!find(countries, { entityId: values.countryCode })
          ),
          'entityId'
        );

        if (
          values.calculatorRequest.parameterId != null &&
          values.countryCode != null &&
          !supportedLeasingParametersIds.includes(values.calculatorRequest.parameterId)
        ) {
          // In this case, the countryCode is changed but the currently selected leasing parameter doesn't support that country.
          // We change the selection of the parameter to one that is supported, and reject the promise, so that the new change in values will be picked up again by the AutoUpdate component
          // If we would have resolved instead, there would be a request done to the backend for the calculations, but with inconsistent values (countryCode - parameterId combination)

          setValues({
            ...values,
            calculatorRequest: {
              ...values.calculatorRequest,
              parameterId: supportedLeasingParametersIds[0] // This assumes that each leasingParameter has at least one supported country. That is guaranteed by the LeasingParametersProvider
            }
          });

          return reject();
        }

        return resolve();
      }),
    [leasingParameters]
  );

  const currentLeasingParameter = find(leasingParameters, {
    entityId: calculatorRequest.parameterId
  });
  if (!currentLeasingParameter) {
    throw Error(
      'No leasing parameter was selected. This should not happen due to the initialValues'
    );
  }

  return (
    <ScForm noValidate onSubmit={handleSubmit} autoComplete="false">
      <PageHelmet pageTitle={t('CALCULATOR__TITLE')} />
      <CalculatorAutoUpdate beforeUpdate={beforeUpdateFormValues} />
      <Card style={cardStyle} headStyle={cardHeadStyle}>
        <Row gutter={16}>
          <ScPrintAsThreeCols span={12} md={6} xl={4}>
            <FastField
              label={`${t('DETAILS_VEHICLE_PURCHASE_PRICE')} (€)`}
              component={FormNumberInput}
              disabled={disabled}
              name="calculatorRequest.purchasePrice"
              placeholder={`${t('DETAILS_VEHICLE_PURCHASE_PRICE')} (€)`}
              value={calculatorRequest.purchasePrice}
            />
          </ScPrintAsThreeCols>
          <ScPrintAsThreeCols span={12} md={6} xl={4}>
            <FastField
              label={t('CALCULATOR_COUNTRY')}
              placeholder={t('CALCULATOR_COUNTRY')}
              disabled={disabled}
              name="countryCode"
              value={countryCode}
              component={FormSelect}
              options={countryCodeOptions}
            />
          </ScPrintAsThreeCols>
          <ScPrintAsThreeCols span={24} md={24} xl={16}>
            <FastField
              label={t('DETAILS_VEHICLE_CATEGORY')}
              disabled={disabled}
              name="calculatorRequest.parameterId"
              value={calculatorRequest.parameterId}
              component={ScVehicleTypeFormRadio}
              options={getVehicleTypeOptions(countryCode)}
            />
          </ScPrintAsThreeCols>
        </Row>
      </Card>

      <Row gutter={16}>
        <Col span={24} md={8} id="cashflow-col">
          <Card
            title={t('CASHFLOW')}
            style={cardStyle}
            headStyle={getColoredCardHeadStyle(
              calculations == null
                ? ColorStatus.Default
                : calculations.cashDeficit > 0
                ? ColorStatus.Error
                : ColorStatus.Success
            )}
            bodyStyle={noPaddingStyle}
          >
            <ScMetricsContainer>
              <ScMetricsGrid>
                <div>{t('CASH_BALANCED')}</div>
                <ScColored
                  colorStatus={
                    calculations
                      ? calculations.cashDeficit > 0
                        ? ColorStatus.Error
                        : ColorStatus.Success
                      : ColorStatus.Default
                  }
                >
                  {calculations ? (calculations.cashDeficit > 0 ? t('NO') : t('YES')) : '-'}
                </ScColored>
                <div>{t('CASH_DEFICIT')}</div>
                <ScColored
                  colorStatus={
                    calculations
                      ? calculations.cashDeficit > 0
                        ? ColorStatus.Error
                        : ColorStatus.Success
                      : ColorStatus.Default
                  }
                >
                  {calculations ? stringifyPrice(calculations.cashDeficit, 2, language) : '-'}
                </ScColored>
              </ScMetricsGrid>
            </ScMetricsContainer>
          </Card>
        </Col>

        <Col span={24} md={8} id="roi-checker-col">
          <Card
            title={t('ROI_CHECKER')}
            style={cardStyle}
            headStyle={getColoredCardHeadStyle(
              calculations == null
                ? ColorStatus.Default
                : calculations.ROIDifference < 0
                ? ColorStatus.Error
                : ColorStatus.Success
            )}
            bodyStyle={noPaddingStyle}
          >
            <ScMetricsContainer>
              <ScMetricsGrid>
                <div>{t('ROI')}</div>
                <div>{calculations ? `${calculations.ROI.toFixed(2)} %` : '-'}</div>
                <div>{t('ROI_YEAR')}</div>
                <div>{calculations ? `${calculations.ROIPerYear.toFixed(2)} %` : '-'}</div>
                <div>{t('ROI_TARGET')}</div>
                <div>{calculations ? `${calculations.ROITarget.toFixed(2)} %` : '-'}</div>
                <div>{t('ROI_DIFFERENCE')}</div>
                <ScColored
                  colorStatus={
                    calculations
                      ? calculations.ROIDifference < 0
                        ? ColorStatus.Error
                        : ColorStatus.Success
                      : ColorStatus.Default
                  }
                >
                  {calculations ? `${calculations.ROIDifference.toFixed(2)} %` : '-'}
                </ScColored>
              </ScMetricsGrid>
            </ScMetricsContainer>
          </Card>
        </Col>

        <Col span={24} md={8} id="profitability-col">
          <Card
            title={t('PROFITABILITY')}
            style={cardStyle}
            headStyle={getColoredCardHeadStyle(
              calculations == null
                ? ColorStatus.Default
                : calculations.totalProfit < 0
                ? ColorStatus.Error
                : ColorStatus.Success
            )}
            bodyStyle={noPaddingStyle}
          >
            <ScMetricsContainer>
              <ScMetricsGrid>
                <div>{t('CASH_IN_LEASE_AMOUNT')}</div>
                <ScColored
                  colorStatus={
                    calculations
                      ? calculations.leasingProfit < 0
                        ? ColorStatus.Error
                        : ColorStatus.Success
                      : ColorStatus.Default
                  }
                >
                  {calculations ? stringifyPrice(calculations.leasingProfit, 2, language) : '-'}
                </ScColored>
                <div>{t('CALCULATOR__PROFITABILITY_VEHICLE')}</div>
                <ScColored
                  colorStatus={
                    calculations
                      ? calculations.vehicleProfit < 0
                        ? ColorStatus.Error
                        : ColorStatus.Success
                      : ColorStatus.Default
                  }
                >
                  {calculations ? stringifyPrice(calculations.vehicleProfit, 2, language) : '-'}
                </ScColored>
                <div>{t('CALCULATOR_TOTAL')}</div>
                <ScColored
                  colorStatus={
                    calculations
                      ? calculations.totalProfit < 0
                        ? ColorStatus.Error
                        : ColorStatus.Success
                      : ColorStatus.Default
                  }
                >
                  {calculations ? stringifyPrice(calculations.totalProfit, 2, language) : '-'}
                </ScColored>
              </ScMetricsGrid>
            </ScMetricsContainer>
          </Card>
        </Col>
      </Row>

      <Row gutter={16}>
        <ScPrintAsTwoCols span={24} md={12}>
          <Card title={t('CALCULATOR_LEASING_TITLE')} style={cardStyle} headStyle={cardHeadStyle}>
            <Card.Grid hoverable={false} style={fullWidthStyle}>
              <Row gutter={16}>
                <ScPrintAsTwoCols span={12}>
                  <FastField
                    label={`${t('CALCULATOR_SALES_PRICE')} (€)`}
                    component={FormNumberInput}
                    disabled={disabled}
                    name="calculatorRequest.salesPrice"
                    placeholder={`${t('CALCULATOR_SALES_PRICE')} (€)`}
                    step={50}
                    min={0}
                    value={calculatorRequest.salesPrice}
                  />
                </ScPrintAsTwoCols>
                <ScPrintAsTwoCols span={12}>
                  <FastField
                    label={`${t('CALCULATOR_DEPOSIT')} (€)`}
                    component={FormNumberInput}
                    disabled={disabled}
                    name="calculatorRequest.deposit"
                    placeholder={`${t('CALCULATOR_DEPOSIT')} (€)`}
                    step={50}
                    min={0}
                    value={calculatorRequest.deposit}
                  />
                </ScPrintAsTwoCols>
                <ScPrintAsTwoCols span={12}>
                  <FastField
                    label={`${t('CALCULATOR_RESIDUAL_VALUE')} (€)`}
                    component={FormNumberInput}
                    disabled={disabled}
                    name="calculatorRequest.residualValue"
                    placeholder={`${t('CALCULATOR_RESIDUAL_VALUE')} (€)`}
                    step={50}
                    min={0}
                    value={calculatorRequest.residualValue}
                  />
                </ScPrintAsTwoCols>
                <ScPrintAsTwoCols span={12}>
                  <FastField
                    label={`${t('CALCULATOR_INTEREST_RATE')} (%)`}
                    component={FormNumberInput}
                    disabled={disabled}
                    name="calculatorRequest.interestRatePercentage"
                    placeholder={`${t('CALCULATOR_INTEREST_RATE')} (%)`}
                    step={1}
                    min={0}
                    max={100}
                    value={calculatorRequest.interestRatePercentage}
                  />
                </ScPrintAsTwoCols>
                <ScPrintAsTwoCols span={12}>
                  <FastField
                    label={`${t('CALCULATOR_ADMINISTRATION_FEE')} (€)`}
                    component={FormNumberInput}
                    disabled={disabled}
                    name="calculatorRequest.monthlyAdministrationFee"
                    placeholder={`${t('CALCULATOR_ADMINISTRATION_FEE')} (€)`}
                    step={10}
                    min={0}
                    value={calculatorRequest.monthlyAdministrationFee}
                  />
                </ScPrintAsTwoCols>
                <ScPrintAsTwoCols span={12}>
                  <FastField
                    label={`${t('CALCULATOR_START_UP_FEE')} (%)`}
                    component={FormNumberInput}
                    disabled={disabled}
                    name="calculatorRequest.startupFeePercentage"
                    placeholder={`${t('CALCULATOR_START_UP_FEE')} (%)`}
                    step={1}
                    min={0}
                    max={100}
                    value={calculatorRequest.startupFeePercentage}
                  />
                </ScPrintAsTwoCols>
                <ScPrintAsTwoCols span={24}>
                  <FastField
                    label={t('CALCULATOR_DURATION')}
                    component={FormSlider}
                    disabled={disabled}
                    name="calculatorRequest.durationInMonths"
                    step={
                      isAdmin
                        ? CALCULATOR_DURATION_IN_MONTHS_SLIDER_STEP_ADMINS
                        : CALCULATOR_DURATION_IN_MONTHS_SLIDER_STEP
                    }
                    min={CALCULATOR_DURATION_IN_MONTHS_SLIDER_MIN}
                    max={
                      isAdmin
                        ? CALCULATOR_DURATION_IN_MONTHS_SLIDER_MAX_ADMINS
                        : CALCULATOR_DURATION_IN_MONTHS_SLIDER_MAX
                    }
                    value={calculatorRequest.durationInMonths}
                    formatValueLabel={formatDurationInMonths}
                  />
                </ScPrintAsTwoCols>
              </Row>
            </Card.Grid>

            <ScTotalAmountCardGrid hoverable={false} style={fullWidthAndCenteredStyle}>
              {t('CALCULATOR_TOTAL_LEASE')}
              <ScTotalAmountValue>
                {calculations ? stringifyPrice(calculations.leasingAmount, 2, language) : '-'}
              </ScTotalAmountValue>
            </ScTotalAmountCardGrid>
          </Card>
        </ScPrintAsTwoCols>

        <ScPrintAsTwoCols span={24} md={12}>
          <Card title={t('CALCULATOR_BANK_TITLE')} style={cardStyle} headStyle={cardHeadStyle}>
            <Card.Grid hoverable={false} style={fullWidthStyle}>
              <ScBankFormSectionRow gutter={16}>
                <ScPrintAsTwoCols span={24} md={12}>
                  <FastField
                    label={`${t('CALCULATOR_SALES_PRICE')} (€)`}
                    component={FormNumberInput}
                    disabled
                    min={0}
                    name="calculatorRequest.salesPrice"
                    placeholder={`${t('CALCULATOR_SALES_PRICE')} (€)`}
                    value={calculatorRequest.salesPrice}
                  />
                </ScPrintAsTwoCols>
                <ScPrintAsTwoCols span={24} md={12}>
                  <FastField
                    label={`${t('CALCULATOR_DEPOSIT')} (€)`}
                    component={FormNumberInput}
                    min={0}
                    disabled={disabled}
                    name="calculatorRequest.bankDeposit"
                    placeholder={`${t('CALCULATOR_DEPOSIT')} (€)`}
                    value={calculatorRequest.bankDeposit}
                  />
                </ScPrintAsTwoCols>
                <ScPrintAsTwoCols span={24} md={12}>
                  <FastField
                    label={`${t('CALCULATOR_RESIDUAL_VALUE')} (€)`}
                    component={FormNumberInput}
                    min={0}
                    disabled={disabled}
                    name="calculatorRequest.bankResidualValue"
                    placeholder={`${t('CALCULATOR_RESIDUAL_VALUE')} (€)`}
                    value={calculatorRequest.bankResidualValue}
                  />
                </ScPrintAsTwoCols>
                <ScPrintAsTwoCols span={24} md={12}>
                  <FastField
                    label={`${t('CALCULATOR_INTEREST_RATE')} (%)`}
                    component={FormNumberInput}
                    disabled={disabled}
                    min={0}
                    name="calculatorRequest.bankInterestRatePercentage"
                    placeholder={`${t('CALCULATOR_INTEREST_RATE')} (%)`}
                    value={calculatorRequest.bankInterestRatePercentage}
                  />
                </ScPrintAsTwoCols>
                <ScPrintAsTwoCols span={24}>
                  <FastField
                    label={t('BANK_LOAN_DURATION_IN_MONTHS')}
                    component={FormSlider}
                    disabled={disabled}
                    name="calculatorRequest.bankDurationInMonths"
                    step={CALCULATOR_BANK_DURATION_IN_MONTHS_SLIDER_STEP}
                    min={CALCULATOR_BANK_DURATION_IN_MONTHS_SLIDER_MIN}
                    max={CALCULATOR_BANK_DURATION_IN_MONTHS_SLIDER_MAX}
                    value={calculatorRequest.bankDurationInMonths}
                    formatValueLabel={formatDurationInMonths}
                  />
                </ScPrintAsTwoCols>
              </ScBankFormSectionRow>
            </Card.Grid>

            <ScTotalAmountCardGrid hoverable={false} style={fullWidthAndCenteredStyle}>
              {t('BANK_LEASE_AMOUNT')}
              <ScTotalAmountValue>
                {calculations ? stringifyPrice(calculations.bankLeasingAmount, 2, language) : '-'}
              </ScTotalAmountValue>
            </ScTotalAmountCardGrid>
          </Card>
        </ScPrintAsTwoCols>
      </Row>

      <Row gutter={16}>
        <ScPrintAsTwoCols span={24} md={12}>
          <Card
            title={t('INSURANCE_TITLE')}
            style={cardStyle}
            bodyStyle={noPaddingStyle}
            headStyle={cardHeadStyle}
          >
            <ScResponsiveCardGrid hoverable={false}>
              <Row gutter={16} align="middle" type="flex">
                <ScPrintAsTwoCols span={24}>
                  <FastField
                    label={`${t('INSURANCE_AMOUNT')} (€)`}
                    component={FormNumberInput}
                    min={0}
                    disabled={disabled}
                    name="calculatorRequest.insuranceAmount"
                    placeholder={`${t('INSURANCE_AMOUNT')} (€)`}
                    value={calculatorRequest.insuranceAmount}
                  />
                </ScPrintAsTwoCols>
                <ScPrintAsTwoCols span={24}>
                  <FastField
                    label={`${t('MARGIN_PERCENTAGE')} (%)`}
                    component={FormNumberInput}
                    min={0}
                    disabled={disabled}
                    name="calculatorRequest.marginPercentage"
                    placeholder={`${t('MARGIN_PERCENTAGE')} (%)`}
                    value={calculatorRequest.marginPercentage}
                  />
                </ScPrintAsTwoCols>
                <Col span={18}>
                  <FastField
                    label={t('INSURANCE_CIVIL_LIABILITIES')}
                    component={FormCheckbox}
                    disabled={disabled}
                    name="calculatorRequest.includesCivilLiability"
                    value={calculatorRequest.includesCivilLiability}
                  />
                </Col>
                <Col span={6}>
                  <ScRightAlignedText>
                    {stringifyPrice(currentLeasingParameter.civilLiability, 2, language)}
                  </ScRightAlignedText>
                </Col>
                <Col span={18}>
                  <FastField
                    label={t('INSURANCE_SPECIAL_INSURANCE')}
                    component={FormCheckbox}
                    disabled={disabled}
                    name="calculatorRequest.includesSpecialInsurance"
                    value={calculatorRequest.includesSpecialInsurance}
                  />
                </Col>
                <Col span={6}>
                  <ScRightAlignedText>
                    {stringifyPrice(currentLeasingParameter.specialInsurance, 2, language)}
                  </ScRightAlignedText>
                </Col>
                <Col span={18}>
                  <FastField
                    label={t('INSURANCE_ALL_RISKS')}
                    component={FormCheckbox}
                    disabled={disabled}
                    name="calculatorRequest.includesAllRisks"
                    value={calculatorRequest.includesAllRisks}
                  />
                </Col>
                <Col span={6}>
                  <ScRightAlignedText>
                    {calculations
                      ? stringifyPrice(calculations.allRisksInsuranceAmount, 2, language)
                      : '-'}
                  </ScRightAlignedText>
                </Col>
              </Row>
            </ScResponsiveCardGrid>

            <ScResponsiveCardGrid hoverable={false} style={noPaddingStyle}>
              <ScMetricsGrid>
                <div>{t('YEARLY_COST')}</div>
                <ScRightAlignedText>
                  {calculations
                    ? stringifyPrice(calculations.yearlyInsuranceCost, 2, language)
                    : '-'}
                </ScRightAlignedText>
                <div>{t('MONTHLY_COST')}</div>
                <ScRightAlignedText>
                  {calculations
                    ? stringifyPrice(calculations.monthlyInsuranceCost, 2, language)
                    : '-'}
                </ScRightAlignedText>
              </ScMetricsGrid>
            </ScResponsiveCardGrid>
            <ScResponsiveCardGrid hoverable={false} style={noPaddingStyle}>
              <ScMetricsGrid>
                <div>{t('YEARLY_RETAIL_PRICE')}</div>
                <ScRightAlignedText>
                  {calculations
                    ? stringifyPrice(calculations.yearlyInsuranceRetailPrice, 2, language)
                    : '-'}
                </ScRightAlignedText>
                <b>{t('MONTHLY_RETAIL_PRICE')}</b>
                <ScRightAlignedText>
                  <b>
                    {calculations
                      ? stringifyPrice(calculations.monthlyInsuranceRetailPrice, 2, language)
                      : '-'}
                  </b>
                </ScRightAlignedText>
              </ScMetricsGrid>
            </ScResponsiveCardGrid>
          </Card>
        </ScPrintAsTwoCols>

        <ScPrintAsTwoCols span={24} md={12}>
          <Card
            title={t('TAXES_TITLE')}
            style={cardStyle}
            bodyStyle={noPaddingStyle}
            headStyle={cardHeadStyle}
          >
            <ScResponsiveCardGrid hoverable={false}>
              <ScTaxInputsContainer>
                <Row gutter={16} align="middle" type="flex">
                  <Col span={24}>
                    <FastField
                      label={`${t('MARGIN_PERCENTAGE')} (%)`}
                      component={FormNumberInput}
                      min={0}
                      disabled
                      name="calculatorRequest.marginPercentage"
                      placeholder={`${t('MARGIN_PERCENTAGE')} (%)`}
                      value={calculatorRequest.marginPercentage}
                    />
                  </Col>
                  <Col span={18}>
                    <FastField
                      label={t('TAXES_YEARLY_ROAD_TAX')}
                      component={FormCheckbox}
                      disabled={
                        disabled ||
                        (currentLeasingParameter.yearlyRoadTax === 0 &&
                          !calculatorRequest.includesYearlyRoadTax)
                      }
                      name="calculatorRequest.includesYearlyRoadTax"
                      value={calculatorRequest.includesYearlyRoadTax}
                    />
                  </Col>
                  <Col span={6}>
                    <ScRightAlignedText disabled={currentLeasingParameter.yearlyRoadTax === 0}>
                      {stringifyPrice(currentLeasingParameter.yearlyRoadTax, 2, language)}
                    </ScRightAlignedText>
                  </Col>
                  <Col span={18}>
                    <FastField
                      label={t('TAXES_ONE_TIME_ROAD_TAX')}
                      component={FormCheckbox}
                      disabled={
                        disabled ||
                        (currentLeasingParameter.oneTimeRoadTax === 0 &&
                          !calculatorRequest.includesOneTimeRoadTax)
                      }
                      name="calculatorRequest.includesOneTimeRoadTax"
                      value={calculatorRequest.includesOneTimeRoadTax}
                    />
                  </Col>
                  <Col span={6}>
                    <ScRightAlignedText disabled={currentLeasingParameter.oneTimeRoadTax === 0}>
                      {stringifyPrice(currentLeasingParameter.oneTimeRoadTax, 2, language)}
                    </ScRightAlignedText>
                  </Col>
                </Row>
              </ScTaxInputsContainer>
            </ScResponsiveCardGrid>

            <ScResponsiveCardGrid hoverable={false} style={noPaddingStyle}>
              <ScMetricsGrid>
                <div>{t('ONE_TIME_COST')}</div>
                <ScRightAlignedText>
                  {calculations ? stringifyPrice(calculations.oneTimeTaxCost, 2, language) : '-'}
                </ScRightAlignedText>
                <div>{t('YEARLY_COST')}</div>
                <ScRightAlignedText>
                  {calculations ? stringifyPrice(calculations.yearlyTaxCost, 2, language) : '-'}
                </ScRightAlignedText>
                <div>{t('MONTHLY_COST')}</div>
                <ScRightAlignedText>
                  {calculations ? stringifyPrice(calculations.monthlyTaxCost, 2, language) : '-'}
                </ScRightAlignedText>
              </ScMetricsGrid>
            </ScResponsiveCardGrid>
            <ScResponsiveCardGrid hoverable={false} style={noPaddingStyle}>
              <ScMetricsGrid>
                <div>{t('YEARLY_RETAIL_PRICE')}</div>
                <ScRightAlignedText>
                  {calculations
                    ? stringifyPrice(calculations.yearlyTaxRetailPrice, 2, language)
                    : '-'}
                </ScRightAlignedText>
                <b>{t('MONTHLY_RETAIL_PRICE')}</b>
                <ScRightAlignedText>
                  <b>
                    {calculations
                      ? stringifyPrice(calculations.monthlyTaxRetailPrice, 2, language)
                      : '-'}
                  </b>
                </ScRightAlignedText>
              </ScMetricsGrid>
            </ScResponsiveCardGrid>
          </Card>
        </ScPrintAsTwoCols>
      </Row>

      <Card
        title={t('CALCULATOR_MONTHLY_PAYMENTS')}
        style={totalCardStyle}
        headStyle={cardHeadStyle}
      >
        <Row type="flex" justify="space-around">
          <ScPrintAsFourCols span={24} sm={12} md={6}>
            <ScCenteredText>
              {t('CALCULATOR_LEASING_TITLE')}
              <ScTotalAmountValue>
                {calculations ? stringifyPrice(calculations.leasingAmount, 2, language) : '-'}
              </ScTotalAmountValue>
            </ScCenteredText>
          </ScPrintAsFourCols>
          <ScPrintAsFourCols span={24} sm={12} md={6}>
            <ScCenteredText>
              {t('CALCULATOR_BANK_TITLE')}
              <ScTotalAmountValue>
                {calculations ? stringifyPrice(calculations.bankLeasingAmount, 2, language) : '-'}
              </ScTotalAmountValue>
            </ScCenteredText>
          </ScPrintAsFourCols>
          <ScPrintAsFourCols span={24} sm={12} md={6}>
            <ScCenteredText>
              {`${t('INSURANCE_TITLE')} + ${t('TAXES_TITLE')}`}
              <ScTotalAmountValue>
                {calculations
                  ? stringifyPrice(calculations.monthlyInsuranceAndTaxesRetailPrice, 2, language)
                  : '-'}
              </ScTotalAmountValue>
            </ScCenteredText>
          </ScPrintAsFourCols>
          <ScPrintAsFourCols span={24} sm={12} md={6}>
            <ScCenteredText>
              {t('CALCULATOR_TOTAL')}
              <ScTotalAmountValue>
                {calculations ? stringifyPrice(calculations.totalMonthlyPayment, 2, language) : '-'}
              </ScTotalAmountValue>
            </ScCenteredText>
          </ScPrintAsFourCols>
        </Row>
      </Card>
    </ScForm>
  );
};

export default CalculatorForm;

//
// Utilities
//

const cardStyle: CSSProperties = { marginBottom: '1rem' };
const totalCardStyle: CSSProperties = { ...cardStyle, borderWidth: '3px' };
const cardHeadStyle: CSSProperties = { textAlign: 'center' };
const fullWidthStyle: CSSProperties = { width: '100%' };
const fullWidthAndCenteredStyle: CSSProperties = { width: '100%', textAlign: 'center' };
const noPaddingStyle: CSSProperties = { padding: '0' };

const ScVehicleTypeFormRadio = styled(FormRadio)`
  margin-bottom: 0;
`;
const ScMetricsContainer = styled.div`
  @media (min-width: ${BREAKPOINTS.md}px) {
    height: 156px;
  }
`;
const ScMetricsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5rem;
  padding: 1.5rem;

  @media (min-width: ${BREAKPOINTS.md}px) {
    grid-template-columns: 2fr 1fr;
  }
`;
const ScBankFormSectionRow = styled(Row)`
  @media (min-width: ${BREAKPOINTS.md}px) {
    min-height: 264px;
  }
`;
const ScResponsiveCardGrid = styled(Card.Grid)`
  width: 100% !important;

  @media (min-width: ${BREAKPOINTS.md}px) {
    width: 50% !important;
  }
`;
const ScTotalAmountCardGrid = styled(Card.Grid)``;
const ScTaxInputsContainer = styled.div`
  @media (min-width: ${BREAKPOINTS.md}px) {
    height: 177px;
  }
`;
const ScCenteredText = styled.div`
  text-align: center;
`;
const ScRightAlignedText = styled.div<{ disabled?: boolean }>`
  text-align: right;
  ${props => props.disabled && `color: rgba(0, 0, 0, 0.25);`}
`;
const ScColored = styled.div<{ colorStatus: ColorStatus }>`
  color: ${props => {
    switch (props.colorStatus) {
      case ColorStatus.Success:
        return props.theme.colors.success;
      case ColorStatus.Error:
        return props.theme.colors.error;
      default:
        return null;
    }
  }};
`;
const ScTotalAmountValue = styled.div`
  font-size: 2rem;
  font-weight: 600;
`;
const ScPrintAsFourCols = styled(Col)``;
const ScPrintAsThreeCols = styled(Col)``;
const ScPrintAsTwoCols = styled(Col)``;

const ScForm = styled.form`
  @media print {
    .ant-card-head {
      min-height: initial;
      font-size: 0.6rem;

      .ant-card-head-title {
        padding: 0;
      }
    }

    .ant-card-body,
    .ant-card-grid {
      padding: 0.5rem;
      font-size: 0.6rem;
    }

    ${ScTotalAmountCardGrid}, .ant-slider {
      display: none;
    }

    ${ScTotalAmountValue} {
      font-size: initial;
      font-weight: initial;
    }

    ${ScMetricsGrid} {
      padding: 0.5rem;
      font-size: 0.6rem;
    }

    ${ScInputLabel}, .ant-input-number-input-wrap, .ant-select-selection-selected-value, .ant-radio-button-wrapper, .ant-checkbox-wrapper {
      font-size: 0.6rem;
      padding: 0;
    }

    .ant-radio-button-wrapper {
      display: none;

      &.ant-radio-button-wrapper-checked {
        display: inline-block;
      }
    }

    .ant-input-number-input,
    .ant-input-number,
    .ant-select-selection,
    .ant-radio-button-wrapper {
      height: inherit;
    }

    .ant-select-selection__rendered,
    .ant-radio-button-wrapper {
      line-height: 16px;
      margin: 0;
    }

    .ant-select-arrow {
      display: none;
    }

    .ant-form-item-control {
      line-height: 25px;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: inherit;
    }

    .ant-input-number,
    .ant-select-selection,
    .ant-radio-button-wrapper {
      border: 0 !important;
    }

    .ant-input-number-disabled {
      background-color: transparent;
    }

    .ant-input-number-input-wrap input {
      padding: 0;
    }

    #cashflow-col,
    #roi-checker-col,
    #profitability-col,
    ${ScPrintAsThreeCols} {
      display: block;
      box-sizing: border-box;
      width: 33.33333333%;
    }

    ${ScPrintAsFourCols} {
      display: block;
      box-sizing: border-box;
      width: 25%;
    }

    ${ScPrintAsTwoCols} {
      display: block;
      box-sizing: border-box;
      width: 50%;
    }
  }
`;

const getColoredCardHeadStyle = memoize(
  (colorStatus: ColorStatus): CSSProperties => {
    if (colorStatus === ColorStatus.Default) {
      return cardHeadStyle;
    } else if (colorStatus === ColorStatus.Error) {
      return {
        ...cardHeadStyle,
        color: theme.colors.white,
        background: theme.colors.errorLight
      };
    }
    return { ...cardHeadStyle, color: theme.colors.white, background: theme.colors.successLight };
  }
);

const CalculatorAutoUpdate = withTypes<FormValues>(AutoUpdate);
