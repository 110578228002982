import { message } from 'antd';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import * as Yup from 'yup';
import { requestEmailCode } from '../context/auth/authClient';
import { history } from '../context/router/history';
import { useTenant } from '../context/tenant/TenantProvider';
import { useLoginData } from './LoginDataProvider';
import { ScButton, ScContainer, ScFormInput, ScTitle } from './styles';

type LoginEmailFormValues = {
  email: string;
};

const initialValues: LoginEmailFormValues = {
  email: ''
};

const LoginEmailForm = ({ location }: RouteComponentProps<any>) => {
  const { t } = useTranslation();
  const { tenantId } = useTenant();
  const { setEmail } = useLoginData();

  const onSubmit = useCallback(
    async (values: LoginEmailFormValues, actions: FormikActions<LoginEmailFormValues>) => {
      const { promise } = requestEmailCode(tenantId, values.email);
      await promise.then(({ error }) => {
        if (error == null) {
          setEmail(values.email);
          history.push('/login/code', location.state);
        } else {
          message.warning(t('AUTH__REQUEST_CODE_ERROR'));
        }
      });
      actions.setSubmitting(false);
    },
    [setEmail, tenantId, location.state, t]
  );

  const formSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .email(t('FORM__INVALID_EMAIL'))
          .required(t('FORM__REQUIRED'))
      }),
    [t]
  );

  return (
    <ScContainer>
      <ScTitle>{t('AUTH__SIGN_IN')}</ScTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={true}
        validateOnChange={false}
        validationSchema={formSchema}
      >
        {(formProps: FormikProps<LoginEmailFormValues>) => (
          <form noValidate onSubmit={formProps.handleSubmit}>
            <Field
              id="email"
              component={ScFormInput}
              disabled={formProps.isSubmitting}
              name="email"
              placeholder={t('AUTH__EMAIL_PLACEHOLDER')}
              size="large"
              value={formProps.values.email}
              submitOnPaste
            />

            <ScButton
              loading={formProps.isSubmitting}
              onClick={formProps.handleSubmit as any}
              type="primary"
              htmlType="submit"
            >
              {t('AUTH__CONTINUE')}
            </ScButton>
          </form>
        )}
      </Formik>
    </ScContainer>
  );
};
export default LoginEmailForm;
