import { intersection } from 'lodash';
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react';

type ContextValue = {
  addLoader: (loadKey: string) => void;
  removeLoader: (loadKey: string) => void;
  getIsReady: (loaders?: string[]) => boolean;
};

const SplashContext = React.createContext<ContextValue | undefined>(undefined);

const SplashProvider = (props: PropsWithChildren<any>) => {
  const [loaders, setLoaders] = useState<string[]>([]);

  // the asyncronous nature of the hooks lead to an isReady value that was not up-to-date
  // but we still wanted things to change when the loaders changed,
  // so we kept the useState and passed the loaders to that it would propagate changes
  const actualLoaders = useRef<string[]>([]);

  const addLoader = useCallback((loadKey: string) => {
    if (!actualLoaders.current.includes(loadKey)) {
      actualLoaders.current = [...actualLoaders.current, loadKey];
      setLoaders(actualLoaders.current);
    }
  }, []);

  const removeLoader = useCallback((loadKey: string) => {
    if (actualLoaders.current.includes(loadKey)) {
      actualLoaders.current = actualLoaders.current.filter(l => l !== loadKey);
      setLoaders(actualLoaders.current);
    }
  }, []);

  const getIsReady = useCallback((whitelistedLoaders?: string[]) => {
    return whitelistedLoaders != null
      ? intersection(whitelistedLoaders, actualLoaders.current).length === 0
      : actualLoaders.current.length === 0;
  }, []);

  const value = useMemo(
    () => ({
      addLoader,
      removeLoader,
      loaders,
      getIsReady
    }),
    [addLoader, removeLoader, getIsReady, loaders]
  );

  return <SplashContext.Provider value={value} {...props} />;
};

const useSplash = (): ContextValue => {
  const context = useContext(SplashContext);
  if (context === undefined) {
    throw new Error('useSplash must be used within an SplashProvider');
  }
  return context;
};

export { useSplash, SplashProvider };
