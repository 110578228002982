import bowser from 'bowser';
import React from 'react';
import UnsupportedBrowser from './UnsupportedBrowser';

const browser = bowser.getParser(window.navigator.userAgent);
const isInvalidBrowser = browser.satisfies({
  windows: {
    'internet explorer': '>0',
    'Microsoft Edge': '<15',
    'Samsung Internet for Android': '>0'
  }
});

const BrowserChecker = ({ children }: any) => {
  if (isInvalidBrowser) {
    return <UnsupportedBrowser />;
  }
  return children;
};

export default BrowserChecker;
