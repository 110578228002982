import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FieldProps } from 'formik';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ScFormItem } from './utils';

type Props = {
  className?: string;
  label: string;
  value: boolean;
} & FieldProps<any>;

const FormCheckbox = ({ form, field, label, value, className, ...props }: Props) => (
  <ScFormItem className={className} {...field}>
    <ScCheckbox
      onChange={(e: CheckboxChangeEvent) => field.onChange(field.name)(e.target.checked)}
      checked={value}
      {...props}
    >
      {label}
    </ScCheckbox>
  </ScFormItem>
);

export default FormCheckbox;

//
// Utils
//

const checkboxCheckedPrintCss = css`
  .ant-checkbox {
    display: none;
  }
  &:before {
    content: '✓';
    font-size: 19px;
    line-height: 0.8;
  }
`;
const ScCheckbox = styled(Checkbox)`
  && {
    line-height: normal;
  }
  @media print {
    ${props => props.checked && checkboxCheckedPrintCss}
  }
`;
