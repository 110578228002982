import React, { ReactElement } from 'react';
import styled from 'styled-components';
import '../context/i18n'; // import i18n just so that it gets bundled
import { BREAKPOINTS } from '../utils/constants';

const ScTextContainer = styled.div`
  text-align: center;
  padding: 2rem 1rem 1rem;
  color: #8c8c8c;
  font-size: 0.9rem;

  @media (min-width: ${BREAKPOINTS.sm}px) {
    background: #fff;
    position: absolute;
    min-width: 400px;
    max-width: 550px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -51%);
    bottom: auto;
    padding: 3rem;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.15);
    border-radius: 2px;
  }
`;
const ScTitle = styled.div`
  font-size: 1.5rem;
  padding: 1.5rem 0;
  font-weight: 600;
  line-height: 1.5rem;
  color: #5a5a5a;
`;
const ScImage = styled.div<{ url: string }>`
  background: url('${props => props.url}') no-repeat center center;
  background-size: cover;
  height: 4rem;
  width: 5rem;
  margin: auto;
`;

type Props = {
  title: string;
  imageUrl?: string;
  children: ReactElement;
};

const ErrorPage = ({ title, imageUrl, children }: Props) => {
  return (
    <ScTextContainer>
      {imageUrl == null ? null : <ScImage url={imageUrl} />}
      <ScTitle>{title}</ScTitle>
      {children}
    </ScTextContainer>
  );
};

export default ErrorPage;
