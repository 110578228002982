import React, { Suspense } from 'react';
import { Router } from 'react-router';
import { ThemeProvider } from 'styled-components/macro';
import { Loading } from '../components/Loading';
import { LoginDataProvider } from '../login/LoginDataProvider';
import { theme } from '../theme/theme';
import AnalyticsTracking from './analytics/AnalyticsTracking';
import { AuthProvider } from './auth/AuthProvider';
import './i18n'; // import i18n just so that it gets bundled
import AntI18nProvider from './i18n/AntI18nProvider';
import { LeasingParametersProvider } from './leasing-parameters/LeasingParametersProvider';
import { OnlineProvider } from './online/OnlineProvider';
import { history } from './router/history';
import { Splash } from './splash/Splash';
import { SplashProvider } from './splash/SplashProvider';
import { TenantProvider } from './tenant/TenantProvider';
import { UserProvider } from './user/UserProvider';

export const AppProviders = ({ children }: any) => {
  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<Loading />}>
        <OnlineProvider>
          <TenantProvider>
            <Router history={history}>
              <AnalyticsTracking>
                <SplashProvider>
                  <AntI18nProvider>
                    <AuthProvider>{children}</AuthProvider>
                  </AntI18nProvider>
                </SplashProvider>
              </AnalyticsTracking>
            </Router>
          </TenantProvider>
        </OnlineProvider>
      </Suspense>
    </ThemeProvider>
  );
};

export const AuthenticatedAppProviders = ({ children }: any) => {
  return (
    <LeasingParametersProvider>
      <UserProvider>
        <Splash>{children}</Splash>
      </UserProvider>
    </LeasingParametersProvider>
  );
};

export const UnauthenticatedAppProviders = ({ children }: any) => {
  return (
    <LoginDataProvider>
      <Splash>{children}</Splash>
    </LoginDataProvider>
  );
};
