import { OptionT } from '@hypercharge/hyperleasing-commons/lib/types/common';
import { Radio } from 'antd';
import { FieldProps } from 'formik';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { ScError, ScFormItem, ScInputLabel } from './utils';

type Props = {
  className?: string;
  label?: string;
  options: OptionT[];
} & FieldProps<any>;

const FormRadio = ({ form: { errors }, field, label, options, className, ...props }: Props) => {
  const isError = useMemo(() => get(errors, field.name) != null, [errors, field]);
  const errorMessage = useMemo(
    () => (isError ? <ScError>{get(errors, field.name)}</ScError> : null),
    [isError, errors, field.name]
  );
  return (
    <>
      {label != null && <ScInputLabel>{label}</ScInputLabel>}
      <ScFormItem
        hasFeedback={isError}
        help={errorMessage}
        validateStatus={isError ? 'error' : undefined}
        className={className}
        {...field}
      >
        <Radio.Group onChange={e => field.onChange(field.name)(e.target.value)} {...props}>
          {options.map(({ value, label, disabled }) => (
            <Radio.Button key={value} value={value} disabled={disabled}>
              {label}
            </Radio.Button>
          ))}
        </Radio.Group>
      </ScFormItem>
    </>
  );
};

export default FormRadio;
